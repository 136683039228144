<template>
  <v-row align="center" justify="center" class="mt-10">
    <v-col cols="12" class="header mb-2 pl-2 py-4">
      <span style="font-size: 24px; font-weight: 400"
        >{{ $t("doc2.header") }} {{ $t("non_im.(Personnel)") }}</span
      >
      <v-btn
        style="float: right"
        color="grey"
        dark
        small
        @click="opendialoghelp()"
      >
        <v-icon class="mr-1" small>mdi-help</v-icon>Status</v-btn
      >
    </v-col>
    <v-card outlined width="100vw" class="pa-4">
      <v-row>
        <v-col cols="4"
          ><v-text-field label="search" solo dense></v-text-field
        ></v-col>
        <v-col cols="4"
          ><v-select
            v-model="statusFilter"
            :items="[
              'All',
              'Waiting for verification',
              'Request verified',
              'Approved',
              'Pending',
              'Declined',
            ]"
            label="Filter"
            solo
            dense
            @change="statusFilters(statusFilter)"
          ></v-select
        ></v-col>
      </v-row>
      <v-col cols="12">
        <v-data-table v-if="EngLang" :headers="headersEN" :items="items">
          <template v-slot:[`item.name`]="{ item }">
            <span>{{ item.name_of_user }}</span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <span>{{ convertDate(item.createdAt) }} </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :color="
                item.status == 'Waiting for verification'
                  ? 'orange'
                  : item.status == 'Request verified'
                  ? 'blue'
                  : item.status == 'Approved'
                  ? 'green'
                  : item.status == 'Pending'
                  ? 'orange'
                  : 'red'
              "
              dark
            >
              {{ item.status }}
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  v-if="showstatus"
                  class="mr-1"
                  @click="changeStatus(item)"
                  >mdi-checkbox-marked</v-icon
                >
              </template>
              <span>Change status</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  v-if="
                    userData.user_type == 'ADMIN' ||
                    userData.user_type == 'SUPERADMIN'
                  "
                  class="mr-1"
                  @click="onClickExport(item)"
                  >mdi-download</v-icon
                >
              </template>
              <span>Download</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  v-if="item.status !== 'SAVE DRAFT'"
                  class="mr-1"
                  @click="goToView(item.id)"
                  >mdi-eye</v-icon
                >
              </template>
              <span>View</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  v-if="
                    userData.user_type == 'ADMIN' ||
                    userData.user_type == 'SUPERADMIN'
                      ? true
                      : item.status == 'SAVE DRAFT'
                      ? true
                      : false
                  "
                  class="mr-1"
                  @click="goToEdit(item.id)"
                  :disabled="item.status === 'Approved'"
                  >mdi-pencil</v-icon
                >
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="mr-1" @click="DeleteItem(item.id)"
                  >mdi-delete</v-icon
                >
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <!-- แยกภาษา -->
        <v-data-table v-if="!EngLang" :headers="headersTH" :items="items">
          <template v-slot:[`item.name`]="{ item }">
            <span>{{ item.name_of_user }}</span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <span>{{ convertDate(item.createdAt) }} </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :color="
                item.status == 'Waiting for verification'
                  ? 'orange'
                  : item.status == 'Request verified'
                  ? 'blue'
                  : item.status == 'Approved'
                  ? 'green'
                  : item.status == 'Pending'
                  ? 'orange'
                  : 'red'
              "
              dark
            >
              {{ item.status }}
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  v-if="showstatus"
                  class="mr-1"
                  @click="changeStatus(item)"
                  >mdi-checkbox-marked</v-icon
                >
              </template>
              <span>Change status</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  v-if="
                    userData.user_type == 'ADMIN' ||
                    userData.user_type == 'SUPERADMIN'
                  "
                  class="mr-1"
                  @click="onClickExport(item)"
                  >mdi-download</v-icon
                >
              </template>
              <span>Download</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  v-if="item.status !== 'SAVE DRAFT'"
                  class="mr-1"
                  @click="goToView(item.id)"
                  >mdi-eye</v-icon
                >
              </template>
              <span>View</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  v-if="
                    userData.user_type == 'ADMIN' ||
                    userData.user_type == 'SUPERADMIN'
                      ? true
                      : item.status == 'SAVE DRAFT'
                      ? true
                      : false
                  "
                  class="mr-1"
                  @click="goToEdit(item.id)"
                  :disabled="item.status === 'Approved'"
                  >mdi-pencil</v-icon
                >
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="mr-1" @click="DeleteItem(item.id)"
                  >mdi-delete</v-icon
                >
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-card>
    <DialogStatus
      :dialog1="dialog"
      :status="statusitem"
      :EngLang="EngLang"
      :URLenpoint="URLenpoint"
      @closeDialog="closeDialog()"
    />
    <DialogStatusDetail
      :dialoghelp1="dialoghelp"
      :EngLang="EngLang"
      @closeDialoghelp="closeDialoghelp()"
    />
  </v-row>
</template>

<script>
import { Decode } from "@/services";
import moment from "moment";
import DialogStatus from "@/components/Dialog/DialogStatus";
import DialogStatusDetail from "@/components/Dialog/DialogStatusDetail";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
export default {
  components: {
    DialogStatus,
    DialogStatusDetail,
  },
  data() {
    return {
      showstatus: false,
      showview: false,
      showedit: false,
      URLenpoint: "personnel_visa_extension_change_of_affiliation",
      userData: [],
      count: 0,
      EngLang: true,
      nationalityItems: [],
      headersTH: [
        { text: "ลำดับ", value: "count", align: "center" },
        // { text: "หมายเลขคำขอ", value: "request_no", align: "center" },
        { text: "ชื่อนิสิต/บุคลากร", value: "name", align: "center" },
        { text: "วันที่สร้างเอกสาร", value: "createdAt", align: "center" },
        { text: "สถานะ", value: "status", width: 300 },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      headersEN: [
        { text: "No.", value: "count", align: "center" },
        // { text: "Requested No.", value: "request_no", align: "center" },
        { text: "Name of student/staff", value: "name", align: "center" },
        { text: "Created At", value: "createdAt", align: "center" },
        { text: "Status", value: "status", width: 300 },
        { text: "Action", value: "action", align: "center" },
      ],
      items: [],
      dialog: false,
      dialoghelp: false,
      statusitem: {},
      statusFilter: "All",
      itemsRef: [],
      newdate: new Date(),
      checkMonth: "",
    };
  },
  watch: {
    checkMonth(val) {
      const monthNames = [
        "มกกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ];

      const d = new Date();
      let dateFormat =
        d.getDate() +
        " " +
        monthNames[d.getMonth()] +
        " " +
        (d.getFullYear() * 1 + 543);

      console.log("dateFormat", dateFormat);
      this.Newdate = dateFormat;
    },
  },
  created() {
    const lang = localStorage.getItem("lang");
    if (lang == "en") {
      this.EngLang = true;
      this.itemChangeLang = "name_en";
    } else if (lang == "th") {
      this.EngLang = false;
      this.itemChangeLang = "name_th";
    }
    if (Object.prototype.hasOwnProperty.call(localStorage, "userCUdata")) {
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("userCUdata"))
      );
      console.log("this.userData", this.userData);
      if (this.userData.user_type == "ADMIN") {
        this.getAllAdmin(this.userData.mAffiliationFacultyId);
        this.showstatus = true;
        this.showedit = true;
      } else if (this.userData.user_type == "TEACHER") {
        this.getAllPersonnel(this.userData.id);
      } else {
        this.getAll();
        this.showstatus = true;
        this.showedit = true;
      }
    }
    this.checkMonth = this.convertOnlyMonth(new Date());
  },
  methods: {
    loadFile(url, callback) {
      PizZipUtils.getBinaryContent(url, callback);
    },
    async onClickExport(item) {
      const thaiName = [
        "มกกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ];

      let startYear =
        parseInt(this.convertOnlyYear(item.edu_infor_start)) + parseInt(543);
      let endYear =
        parseInt(this.convertOnlyYear(item.edu_infor_end)) + parseInt(543);
      let untill =
        parseInt(this.convertOnlyYear(item.visa_extension_until)) +
        parseInt(543);
      let expiry =
        parseInt(this.convertOnlyYear(item.visa_expiry_date)) + parseInt(543);

      const monthThaiStart = new Date(item.edu_infor_start);
      console.log("monthThaiStart", monthThaiStart);

      let thaiFormatStart =
        thaiName[monthThaiStart.getMonth()] + " " + startYear;

      const monthThaiEnd = new Date(item.edu_infor_end);
      console.log("monthThaiEnd", monthThaiEnd);

      let thaiFormatEnd = thaiName[monthThaiEnd.getMonth()] + " " + endYear;

      const monthThaiuntill = new Date(item.visa_extension_until);
      console.log("monthThaiuntill", monthThaiuntill);

      let thaiFormatuntil =
        [monthThaiuntill.getDate()] +
        " " +
        thaiName[monthThaiuntill.getMonth()] +
        " " +
        untill;

      const monthThaiexpiry = new Date(item.visa_expiry_date);
      console.log("monthThaiexpiry", monthThaiexpiry);

      let thaiFormatexpiry =
        [monthThaiexpiry.getDate()] +
        " " +
        thaiName[monthThaiexpiry.getMonth()] +
        " " +
        expiry;

      if (item.edu_infor_start == null) {
        thaiFormatStart = "";
      }
      if (item.edu_infor_end == null) {
        thaiFormatEnd = "";
      }
      if (item.visa_extension_until == null) {
        thaiFormatuntil = "";
      }
      if (item.visa_expiry_date == null) {
        thaiFormatexpiry = "";
      }
      console.log("thaiFormatStart", thaiFormatStart);
      console.log("thaiFormatEnd", thaiFormatEnd);
      console.log("thaiFormatuntil", thaiFormatuntil);
      console.log("thaiFormatexpiry", thaiFormatexpiry);
      // const response = await this.axios.get(
      //   `${process.env.VUE_APP_API}/meterTrxs/${item.id}`
      // );

      // let meterTrx = response.data.data
      let that = this;

      console.log("log export", item);
      this.loadFile(
        "https://service-uat-temp.แดร์ประเทศไทย.com/word-template/02.ขออยู่ต่อ-ขอเปลี่ยนสังกัด-อาจารย์ .docx",
        function (error, content) {
          if (error) {
            throw error;
          }
          const zip = new PizZip(content);
          const doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
          });

          doc.setData({
            Newdate: item.Newdate,
            periodStartDate: thaiFormatStart,
            periodEndDate: thaiFormatEnd,

            m_affiliation_faculty_id:
              item.m_affiliation_faculty.id_content_list_th,
            m_affiliation_faculty_address_th:
              item.m_affiliation_faculty.address_th,
            title: item.title,
            give_name: item.give_name,
            middle_name: item.middle_name,
            family_name: item.family_name,
            m_nationality: item.m_nationality.name_th,
            student_id: item.student_id,
            m_affiliation_faculty_name_th: item.m_affiliation_faculty.name_th,

            passport_number: item.passport_number,
            visa_expiry_date: thaiFormatexpiry,
            coor_con_full_name: item.coor_con_full_name,
            coor_con_tel: item.coor_con_tel,
            coor_con_email: item.coor_con_email,
            m_type_of_work: item.m_type_of_work.name_th,

            visa_extension_until: thaiFormatuntil,
            m_affiliation_faculty_dean_director_th:
              item.m_affiliation_faculty.dean_director_th,
            m_affiliation_faculty_position_agent_th:
              item.m_affiliation_faculty.position_agent_th,
          });
          try {
            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render();
          } catch (error) {}
          const out = doc.getZip().generate({
            type: "blob",
            mimeType:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });
          // Output the document using Data-URI
          saveAs(
            out,
            item.request_no + "บุคลากรขออยู่ต่อ-ขอเปลี่ยนสังกัด .docx"
          );
        }
      );
    },

    statusFilters(val) {
      let itemsFilter = [];
      itemsFilter = this.itemsRef.filter((x) => x.status === val);
      if (
        val === "Waiting for verification" ||
        val === "Request verified" ||
        val === "Approved" ||
        val === "Pending" ||
        val === "Declined"
      ) {
        this.items = itemsFilter;
      } else {
        this.items = this.itemsRef;
      }
    },
    DownloadDocument(val) {
      console.log("val", val);
      window.open(
        `https://visa-system-service.yuzudigital.com/printPdf/professor-researcher-staff/02-request-stay-change-affiliation-teacher/${val}`
      );
    },
    opendialoghelp() {
      this.dialoghelp = true;
    },
    closeDialoghelp() {
      this.dialoghelp = false;
    },
    closeDialog() {
      this.dialog = false;
      window.location.reload();
    },
    async changeStatus(item) {
      console.log(item);
      this.statusitem = item;
      this.dialog = true;
      this.URLenpoint = "personnel_visa_extension_change_of_affiliation";
    },
    goToEdit(id) {
      console.log("item", id);
      this.$router.push(`/Edit-Personnel-Doc2?id=${id}`);
    },
    goToView(id) {
      console.log("item", id);
      this.$router.push(`/View-Personnel-Doc2?id=${id}`);
    },
    async DeleteItem(id) {
      this.$swal({
        text: this.EngLang
          ? "Do you want to delete ?"
          : "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.EngLang ? "Cancel" : "ยกเลิก",
        confirmButtonText: this.EngLang ? "Yes" : "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.userData = JSON.parse(
            Decode.decode(localStorage.getItem("userCUdata"))
          );
          const auth = {
            headers: {
              Authorization: `Bearer ${this.userData.token}`,
            },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/personnel_visa_extension_change_of_affiliation/${id}`,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.$router.go(0);
        }
      });
    },
    async getAll() {
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      // const data = {
      //   ...this.form,
      // };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/personnel_visa_extension_change_of_affiliation`
      );
      console.log("response", response.data.data);
      this.items = response.data.data;
      this.itemsRef = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        this.items[i].name_of_user =
          (this.items[i].user.give_name
            ? this.items[i].user.give_name + " "
            : "") +
          (this.items[i].user.middle_name
            ? this.items[i].user.middle_name + " "
            : "") +
          (this.items[i].user.family_name
            ? this.items[i].user.family_name
            : "");

        let startDateConvert = this.convertMonthYear(
          this.items[i].edu_infor_start
        );
        this.items[i].periodStartDate = startDateConvert;

        let endDateConvert = this.convertMonthYear(this.items[i].edu_infor_end);
        this.items[i].periodEndDate = endDateConvert;

        // let newdateConvert = this.convertnewdate(this.items[i].newdate);
        this.items[i].Newdate = this.Newdate;
      }
    },
    convertDate(val) {
      return moment(val).format("DD/MM/yyyy");
    },
    convertMonthYear(val) {
      return moment(val).format("MM/yyyy");
    },
    // convertnewdate(val) {
    //   return moment(val).format("MM(DD),yyyy");
    // },
    convertOnlyMonth(val) {
      return moment(val).format("MM");
    },
    convertOnlyYear(val) {
      return moment(val).format("yyyy");
    },
    async getAllAdmin(mAffiliationFacultyId) {
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      // const data = {
      //   ...this.form,
      // };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/personnel_visa_extension_change_of_affiliation?mAffiliationFacultyId=${mAffiliationFacultyId}`
      );
      console.log("response Admin", response.data.data);
      this.items = response.data.data;
      this.itemsRef = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        this.items[i].name_of_user =
          (this.items[i].user.give_name
            ? this.items[i].user.give_name + " "
            : "") +
          (this.items[i].user.middle_name
            ? this.items[i].user.middle_name + " "
            : "") +
          (this.items[i].user.family_name
            ? this.items[i].user.family_name
            : "");
      }
    },
    async getAllPersonnel(userId) {
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      console.log("test Personnel");
      // const data = {
      //   ...this.form,
      // };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/personnel_visa_extension_change_of_affiliation?userId=${userId}`
      );
      console.log("response Personnel", response.data.data);
      this.items = response.data.data;
      this.itemsRef = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        this.items[i].name_of_user =
          (this.items[i].user.give_name
            ? this.items[i].user.give_name + " "
            : "") +
          (this.items[i].user.middle_name
            ? this.items[i].user.middle_name + " "
            : "") +
          (this.items[i].user.family_name
            ? this.items[i].user.family_name
            : "");
      }
    },
  },
};
</script>

<style scoped>
.header {
  background-color: #de5c8e;
  padding: 10px;

  color: white;
  border-radius: 5px;
}
</style>