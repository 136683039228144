<template>
  <div>
    <v-dialog v-model="dialoghelp1" max-width="80%" persistent>
      <v-card class="pb-4" v-if="EngLang">
        <v-col cols="12" class="header mb-2 pl-2 py-4">
          <span style="font-size: 24px; font-weight: 400">Status meaning</span>
          <v-btn @click="close()" text dark style="float: right">
            <v-icon >mdi-close</v-icon>
          </v-btn>
        </v-col>
        <v-row
          class="px-4"
          align="center"
          justify="center"
          v-for="(i, index) in aaaa"
          :key="index"
        >
          <v-col cols="6">Status: {{ i.value }}</v-col>
          <v-col cols="6">Meaning: {{ i.textEN }}</v-col>
        </v-row>
      </v-card>
      <v-card class="pb-4" v-if="!EngLang">
        <v-col cols="12" class="header mb-2 pl-2 py-4">
          <span style="font-size: 24px; font-weight: 400">คำอธิบายสเตตัส</span>
          <v-btn @click="close()" text dark style="float: right">
            <v-icon >mdi-close</v-icon>
          </v-btn>
        </v-col>
        <v-row
          class="px-4"
          align="center"
          justify="center"
          v-for="(i, index) in aaaa"
          :key="index"
        >
          <v-col cols="6">สเตตัส: {{ i.value }}</v-col>
          <v-col cols="6">ความหมาย: {{ i.textTH }}</v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Decode } from "@/services";
export default {
  props: {
    dialoghelp1: {
      type: Boolean,
      default: false,
    },

    EngLang: Boolean,
  },
  data() {
    return {
      // dialoghelp: false,
      selectedstatus: "",
      aaaa: [
        {
          value: "Waiting for verification",
          textTH: "รอการอนุมัติเอกสาร",
          textEN: "Waiting for verifying the request and supporting documents.",
        },
        {
          value: "Request verified",
          textTH: "เอกสารอนุมัติแล้ว รอเซ็นเอกสาร",
          textEN:
            "Request verified, cover letter pending for executive’s signature.",
        },
        {
          value: "Approved",
          textTH: "อนุมัติเอกสารแล้ว",
          textEN: "Request and documents approved.",
        },
        {
          value: "Declined for verification",
          textTH: "เอกสารไม่อนุมัติ",
          textEN: "Request and documents declined.",
        },
      ],
      // •	รอการอนุมัติเอกสาร
      // Waiting for verifying the request and supporting documents

      // •	เอกสารอนุมัติแล้ว รอเซ็นเอกสาร
      // Request verified, cover letter pending for executive’s signature

      // •	อนุมัติเอกสาร
      // Request and documents approved

      // •	ไม่อนุมัติ
      // Request and documents declined
    };
  },
  methods: {
    close() {
      this.$emit("closeDialoghelp");
    },
    // async changeStatus(id, selectedstatus) {
    //   console.log("iremssss", id, selectedstatus);
    //   this.userData = JSON.parse(
    //     Decode.decode(localStorage.getItem("userCUdata"))
    //   );
    //   const auth = {
    //     headers: {
    //       Authorization: `Bearer ${this.userData.token}`,
    //     },
    //   };
    //   const data = {
    //     status: selectedstatus,
    //   };
    //   const response = await this.axios.put(
    //     `${process.env.VUE_APP_API}/${this.URLenpoint}/${id}`,
    //     data,
    //     auth
    //   );
    //   console.log("response status", response);
    //   this.$emit("closeDialog");
    // },
  },
};
</script>
<style scoped>
.header {
  background-color: #de5c8e;
  padding: 10px;

  color: white;
  border-radius: 5px;
}
</style>